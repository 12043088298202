<template>
  <div class="accountWrap">
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin: 0 0 10px 0">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>资金信息</el-breadcrumb-item>
        <el-breadcrumb-item>资金详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="topbtns">
        <el-button size="medium" @click="routerBack()" icon="el-icon-arrow-left">返回</el-button>
      </div>
      <el-form class="manageForm" :model="params" :inline="true">
        <el-form-item label="车牌：" prop="vehiclePlate" label-width="96px">
          <el-input class="fromInp" v-model="params.VehicleCarNumber" placeholder="输入车牌号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="开票日期：" prop="date">
          <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="dateChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          <el-button type="primary" size="medium" @click="uploadClick()"
            :disabled="selectedTableItemList == '' ? true : false" icon="el-icon-download">勾选批量下载回单</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading"
        @selection-change="tableSelectionChange">
        <el-table-column type="selection" align="center" width="55" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column type="index" align="center" fixed="left" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillID" fixed="left" label="运单号（自动生成，请勿填写）" width="210"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverPhone }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" label="车牌" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LoadNetWeight" label="装货净重（吨）" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="160" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="签收时间" width="160" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="司机装货数量" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="司机运输单价(人民币)" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.DriverCarryPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.NeedPayMoney | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EntrustedCollectionAmount" label="委托代开运费" show-overflow-tooltip width="160">
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.EntrustedCollectionAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TrustExpensesOfTaxation" label="委托代开服务费/税费" width="180"
          show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TrustExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TrustTotalAmount" label="委托代开运单总金额" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TrustTotalAmount | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="UnloadNetWeight" label="卸货净重（吨）" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="风控路径" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Remark1" label="处理/驳回原因" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayModeName" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="InvoiceAmoutTypeName" label="开票金额" width="200" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column align="center" label="操作" fixed="right" width="240">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="uploadClick(scope.row)" icon="el-icon-download">下载回单
            </el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-download">下载合同
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body class="deep_dialog">
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="flag.showMap" ref="map" :list="mapList">
      </TXmap>
    </el-dialog>
    <!-- 车辆信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.carType" append-to-body class="deep_dialog">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.carType = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.showDriver" append-to-body class="deep_dialog">
      <span slot="title">司机信息</span>
      <DriverInfo :driverInfo="driverInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.showDriver = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap";
import {
  tasksInfoDeatils,
  downloadContract,
  downloadBankUrl,
} from "@/api/finance/account/index";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import { getCarInfo, getDriverDetails, getMapPath } from "@/api/common/common";
export default {
  data() {
    return {
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 开票日期
      date: ["", ""],
      // 请求列表数据参数
      params: {
        pageSize: 10,
        pageIndex: 1,
        VehicleCarNumber: "",
      },
      // 控制弹窗
      flag: {
        // 轨迹回放弹窗
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
      },
      // 车辆信息
      vehicleInfo: {},
      // 司机信息
      driverInfo: {},
      // 轨迹列表
      mapList: [],
      // 表格loading
      loading: true,
      // 已选中的数据
      selectedTableItemList: "",
      queryParams: {
        pageIndex: 0,
        StartDatetime: "", //开始时间
        EndDatetime: "", //结束时间
        currentEnterprise: "1", //企业类型
        EnterpriseFullName: "", //企业名称
      },
    };
  },
  created() {
    // 页面传参
    this.params.InvoiceTaskID = this.$route.query.InvoiceTaskID;
    this.queryParams.pageIndex = this.$route.query.pageIndex
      ? this.$route.query.pageIndex
      : 1;
    this.queryParams.StartDatetime = this.$route.query.StartDatetime
      ? this.$route.query.StartDatetime
      : "";
    this.queryParams.EndDatetime = this.$route.query.EndDatetime
      ? this.$route.query.EndDatetime
      : "";
    this.queryParams.currentEnterprise = this.$route.query.currentEnterprise
      ? this.$route.query.currentEnterprise
      : "";
    this.queryParams.EnterpriseFullName = this.$route.query.EnterpriseFullName
      ? this.$route.query.EnterpriseFullName
      : "";
    // 获取资金详情
    this.tasksInfoDeatils();
  },
  methods: {
    dateChange() {
      this.search();
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/finance/payment/index",
        query: {
          pageIndex: this.queryParams.pageIndex,
          StartDatetime: this.queryParams.StartDatetime
            ? this.queryParams.StartDatetime
            : "",
          EndDatetime: this.queryParams.EndDatetime
            ? this.queryParams.EndDatetime
            : "",
          currentEnterprise: this.queryParams.currentEnterprise
            ? this.queryParams.currentEnterprise
            : "1",
          EnterpriseFullName: this.queryParams.EnterpriseFullName
            ? this.queryParams.EnterpriseFullName
            : "",
          InvoiceType: this.$route.query.InvoiceType || "",
        },
      });
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedTableItemList = e;
    },
    // 下载电子回单
    uploadClick(item) {
      this.loading = true;
      if (item) {
        this.selectedTableItemList = [item];
      }
      let json = {
        InvoiceTaskIDs: [],
        WaybillIDs: this.selectedTableItemList.map((it) => {
          return it.WaybillID;
        }),
      };
      downloadBankUrl(json)
        .then((res) => {
          window.location.href = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 下载合同
    goFacility(item) {
      this.loading = true;
      let data = {
        WaybillID: item.WaybillID,
      };
      downloadContract({ Json: JSON.stringify(data) })
        .then((res) => {
          window.location.href = res.pathName;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看司机信息
    checkUser(item) {
      this.loading = true;
      let data = {};
      getDriverDetails({ UserID: item.DriverUserID, AscriptionUserID: item.UserID })
        .then((res) => {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
          this.driverInfo = data;
          this.flag.showDriver = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading = true;
      let params = {
        CarNumber: item.CarNumber,
      };
      let data = {};
      getCarInfo(params)
        .then((res) => {
          data = Object.assign(
            {},
            res.data.vehicle_baseInfo,
            res.data.vehicle_confirmInfo
          );
          this.vehicleInfo = data;
          this.flag.carType = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    //打开地图
    openMap(item) {
      this.loading = true;
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
        wayBillId: item.WaybillID,
      };
      getMapPath({ json: JSON.stringify(params) })
        .then((res) => {
          this.mapList = res.Track;
          this.flag.showMap = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 清除条件搜索
    resetForm() {
      this.loading = true;
      this.date = ["", ""];
      this.params.VehicleCarNumber = "";
      this.params.pageIndex = 1;
      this.tasksInfoDeatils();
    },
    // 条件搜索
    search() {
      this.loading = true;
      this.params.pageIndex = 1;
      this.tasksInfoDeatils();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.params.pageIndex = e;
      this.tasksInfoDeatils();
    },
    // 获取资金详情
    tasksInfoDeatils() {
      let data = this.params;
      data.StartDatetime = this.date[0];
      data.EndDatetime = this.date[1];
      tasksInfoDeatils({ Json: JSON.stringify(data) }).then((res) => {
        this.loading = false;
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
      });
    },
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
.btnWrap {
  width: 100%;
  text-align: center;
  margin-top: 32px;
}

.facilityBox {
  // margin-top: 30px;
  max-height: 84.5vh;

  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }
}

.searchBox {
  display: flex;
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>
